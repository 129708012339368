import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"

const ContactPage = () => {

    return (
    <Layout>
      Strona kontaktowa
      <Seo title="Kontakt" />
      <h1>Kontakt</h1>
    </Layout>
)}
  
  export default ContactPage